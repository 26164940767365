export const generateStreamArray = (numOfStreams) => Array.from({ length: numOfStreams }, (_, index) => ({
  id: index,
  streamUrl: '',
  robotName: ''
}));

export const generateUpdatedStreamArray = (newLayout, currentStreams) => {
  const newStreamArray = generateStreamArray(newLayout);

  // Copy existing streams to the new array while keeping them in place
  for (let index = 0; index < Math.min(currentStreams.length, newLayout); index += 1) {
    newStreamArray[index].streamUrl = currentStreams[index]?.streamUrl || '';
  }

  return newStreamArray;
};
