import React from 'react';
import { SnackbarContent } from 'notistack';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { useDispatch } from 'react-redux';
import { closeSnackbar } from './snackbarSlice';

const NotificationLayout = styled(Box)(({ theme, variantstyles }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: variantstyles ? variantstyles.background : theme.palette.background.default,
  width: '100%',
  color: variantstyles ? variantstyles.color : theme.palette.primary.light,
  padding: '16px 12px',
  borderRadius: '4px',
  gap: '10px'
}));

const getVariantStyles = (variant, theme) => {
  if (variant === 'success') {
    return {
      background: theme.palette.secondary.light,
      color: theme.palette.secondary.main
    };
  }
  if (variant === 'error') {
    return {
      background: theme.palette.error.light,
      color: theme.palette.error.main
    };
  }
  if (variant === 'warning') {
    return {
      background: theme.palette.warning.light,
      color: theme.palette.warning.main
    };
  }
  if (variant === 'default') {
    return {
      background: theme.palette.background.default,
      color: theme.palette.text.main
    };
  }
  if (variant === 'info') {
    return {
      background: theme.palette.background.default,
      color: theme.palette.primary.main
    };
  }
  if (variant === 'themedError') {
    return {
      background: theme.palette.background.default,
      color: theme.palette.error.main
    };
  }
  return null;
};

const CustomSnackbar = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    id,
    message,
    variant,
    isClearable,
    actions,
    ...other
  } = props;
  const variantStyles = getVariantStyles(variant, theme);
  const actionButtons = [];
  Object.keys(actions).forEach((actionName) => {
    actionButtons.push(
      <Button
        key={actionName}
        onClick={() => actions[actionName]()}
        sx={{
          height: '30px',
          color: 'inherit',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      >
        {actionName}
      </Button>
    );
  });

  return (
    <SnackbarContent ref={ref} {...other}>
      <NotificationLayout variantstyles={variantStyles}>
        <Box sx={{ display: 'flex', flex: '1', justifyContent: 'center' }}>
          <Typography variant="caption1" sx={{ color: variantStyles.color }}>
            {message}
          </Typography>
        </Box>
        {actions && actionButtons}

        {isClearable
         && (
         <Button
           onClick={() => dispatch(closeSnackbar(id))}
           sx={{
             margin: 0,
             padding: 0,
             width: '24px',
             height: '17px',
             backgroundColor: 'transparent',
             '&:hover': {
               backgroundColor: 'transparent'
             }
           }}
         >
           <CloseIcon stroke={theme.palette.text.secondary} />
         </Button>
         )}
      </NotificationLayout>
    </SnackbarContent>
  );
});

CustomSnackbar.propTypes = {
  id: PropTypes.number,
  message: PropTypes.string,
  variant: PropTypes.string,
  isClearable: PropTypes.bool,
  actions: PropTypes.objectOf(PropTypes.func)
};

CustomSnackbar.defaultProps = {
  id: new Date().getTime() + Math.random(),
  message: '',
  variant: 'default',
  isClearable: false,
  actions: null
};

export default CustomSnackbar;
