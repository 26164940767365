//  key-value map. Values represent the default state of a system with no errors
export const errorVariables = {
  estop: false,
  system: 'Online',
  loc: true,
  autonomous: true,
  cpuusage: null // dummy value to include cpuUsage in error count
};

export const oldStatusFilters = ['Active', 'Inactive', 'NA', 'On Mission'];

export const CPU_THRESHOLD = 95;
